// Imports
var ___CSS_LOADER_API_IMPORT___ = require("./node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("./node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/DMSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/DMSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/fonts/Roobert-Light.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"DMSans\";\n  font-style: normal;\n  font-weight: 700;\n  src: local(\"DMSans\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"DMSans\";\n  font-style: normal;\n  font-weight: 400; /* or 400 */\n  src: local(\"DMSans\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Roobert\";\n  font-style: normal;\n  font-weight: 300;\n  src: local(\"Roobert\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"opentype\");\n}\n", ""]);
// Exports
module.exports = exports;
