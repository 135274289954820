import React from "react";
import { View, Text } from "react-native";
import * as Sentry from "sentry-expo";
import AppNavigator from "./src/AppNavigator";
import { Provider as PlanProvider } from "./src/context/PlanContext";
import { Provider as CodeProvider } from "./src/context/CodeContext";
import { Provider as LocalProvider } from "./src/context/LocalContext";
//import LoadingView from "./src/screens/Home/LoadingView";
import "@expo/match-media";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CssBaseline, GeistProvider, Loading } from "@geist-ui/react";
import { Themes, GeistUIThemesFont, GeistUIThemes } from "@geist-ui/core";
import WebFont from "webfontloader";
import LoadingView from "./src/components/LoadingView";

// Sentry.init({
//     dsn: "https://383cf82a8c314563816392fa5931938b@o373137.ingest.sentry.io/5382005",
//     enableInExpoDevelopment: true,
//     debug: true,
//   });

import "./fonts.css";

function App() {
  const [fontsLoaded, setFontsLoaded] = React.useState(false);

  React.useEffect(() => {
    WebFont.load({
      custom: {
        families: ["DMSans:n4,n7", "Roobert:n3"],
        urls: ["/fonts.css"],
      },
      active: () => {
        console.log("Fonts loaded :)");
        setFontsLoaded(true);
      },
      inactive: () => {
        console.log("Inactive");
      },
      fontinactive: (familyName, fvd) => {
        console.log(familyName);
        console.log(fvd);
      },
    });
  }, []);

  const myTheme = Themes.createFromLight({
    type: "customTheme",
    font: {
      sans: "DMSans",
      mono: "DMSans",
      prism: "DMSans",
    },
  });

  if (fontsLoaded) {
    return (
      <LocalProvider>
        <CodeProvider>
          <PlanProvider>
            <GeistProvider themes={[myTheme]} themeType="customTheme">
              <CssBaseline />
              <ToastContainer />
              <AppNavigator />
            </GeistProvider>
          </PlanProvider>
        </CodeProvider>
      </LocalProvider>
    );
  } else {
    return <LoadingView />;
  }
}

export default App;
