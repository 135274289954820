import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Image, ActivityIndicator } from "react-native";

const LoadingView = () => {
  const [desiredHeight, setDesiredHeight] = React.useState(0);

  return (
    <View
      style={{
        backgroundColor: "black",
        height: "100%",
        width: "100%",
        paddingTop: 150,
      }}
    >
      <ActivityIndicator color="white" />
    </View>
  );
};

export default LoadingView;
