import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";

const onErr = async (err, route) => {
  console.log(`${route} error`);
  console.log(err);
  const userIdentification = await AsyncStorage.getItem("userId");
  Sentry.Browser.configureScope(function (scope) {
    scope.setExtra("function_name", route);
    scope.setExtra("id", userIdentification);
    Sentry.Browser.captureException(err);
  });
  toast.error("Having trouble connecting to the server... Try again in a few.");
};

const localReducer = (state, action) => {
  switch (action.type) {
    case "init_database":
      return {
        ...state,
        localDatabase: action.payload,
      };
    case "mod_database":
      return {
        ...state,
        localDatabase: {
          ...state.localDatabase,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

const initDatabase = (dispatch) => (db) => {
  dispatch({ type: "init_database", payload: db });
};

const modDatabase = (dispatch) => (pair) => {
  dispatch({ type: "mod_database", payload: pair });
};

export const { Provider, Context } = createDataContext(
  localReducer,
  {
    initDatabase,
    modDatabase,
  },
  {
    localDatabase: {
      preset: {
        source_id: "acc_PjJiADYam4g9E",
      },
    },
  }
);
