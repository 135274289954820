import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { LinearGradient } from "expo-linear-gradient";
import { Text, Button, Tag } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const botDetails = [
  {
    title: "Identify Debt",
  },
  {
    title: "AML, KYC Compliance",
  },
  {
    title: "Fraud Checks",
  },
  {
    title: "Transfer Funds",
  },
];

const GoalBot = ({ didExecute }) => {
  const lastStepRef = useRef(null);
  const [stepsCompleted, setStepsCompleted] = useState(1);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const loadingVariants = {
    visible: { display: "block", opacity: 1 },
    hidden: { display: "none", opacity: 0 },
  };

  const stepComp = (text, color, delay) => {
    return (
      <motion.div whileHover={{ scale: 1.05 }}>
        <View style={styles.stepContainer}>
          <View
            style={{
              height: 30,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ marginRight: 10 }}>
              <motion.div
                variants={loadingVariants}
                initial="visible"
                animate={"hidden"}
                transition={{ delay: delay + 1 }}
              >
                <ActivityIndicator color={color} />
              </motion.div>
            </View>
            <View
              style={{
                marginRight: 10,
              }}
            >
              <motion.div
                variants={loadingVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ delay: delay + 1 }}
              >
                <Ionicons name="checkmark-circle" size={18} color={color} />
              </motion.div>
            </View>
          </View>

          <Text style={{ color: "white", lineHeight: 1.2 }} font="12px">
            {text}
          </Text>
        </View>
      </motion.div>
    );
  };

  const subStepComp = (key, value, color) => (
    <View
      style={[styles.subStepContainer, { marginLeft: 25, marginVertical: 6 }]}
      key={`${key}${value}`}
    >
      <Text style={{ color: "#C4C4C4" }} small>
        {key}
      </Text>
      <Text style={{ color: "white" }} small>
        {value}
      </Text>
    </View>
  );

  const subComp = (subSteps, color) => (
    <View style={{ marginTop: -3, marginBottom: -3 }}>
      <View style={[styles.subContainer, { borderLeftColor: color }]}>
        {subSteps}
      </View>
    </View>
  );

  const stepVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <View
      style={[
        styles.overviewBox,
        {
          background:
            "linear-gradient(" +
            120 +
            "deg, " +
            "black" +
            ", " +
            "#262626" +
            ")",
        },
      ]}
    >
      <View style={{ flexDirection: "row", marginBottom: -5 }}>
        <Ionicons
          name="logo-codepen"
          size={20}
          color="#00E0A3"
          style={{ marginRight: 8 }}
        />
        <Text style={{ color: "white", fontFamily: "Roobert" }} h5>
          Method API
        </Text>
      </View>

      <Text style={{ color: "grey" }} small>
        Processing Step: {stepsCompleted}/{botDetails.length + 1}
      </Text>

      <View style={{ marginTop: 15 }} />
      {stepComp("Request Received", "#00E0A3")}
      {subComp(<View style={{ height: 10 }} />, "#00E0A3")}

      {didExecute ? (
        <View>
          {botDetails.map((elem, index) => {
            const delay =
              index > 0 &&
              botDetails[index - 1].sub != null &&
              botDetails[index - 1].length > 0
                ? 2 * index + 1
                : 2 * index;
            return (
              <View key={elem.title}>
                <motion.div
                  variants={stepVariants}
                  initial="hidden"
                  animate={"visible"}
                  onAnimationComplete={() =>
                    setStepsCompleted(stepsCompleted + 1)
                  }
                  transition={{ duration: 1, delay: delay }}
                >
                  {stepComp(elem.title, "#00E0A3", delay)}
                </motion.div>

                {elem.sub != null && elem.sub.length > 0 ? (
                  <motion.div
                    variants={stepVariants}
                    initial="hidden"
                    animate={"visible"}
                    transition={{ duration: 2, delay: delay + 1 }}
                  >
                    {subComp(
                      <View>
                        {elem.sub.map((subElem) =>
                          subStepComp(subElem.key, subElem.value)
                        )}
                      </View>,
                      "#00E0A3"
                    )}
                  </motion.div>
                ) : index < botDetails.length - 1 ? (
                  <motion.div
                    variants={stepVariants}
                    initial="hidden"
                    animate={"visible"}
                    transition={{ duration: 2, delay: delay + 1 }}
                  >
                    {subComp(<View style={{ height: 10 }} />, "#00E0A3")}
                  </motion.div>
                ) : null}
              </View>
            );
          })}
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  keyField: {
    backgroundColor: "black",
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 6,
    marginTop: 10,
    width: "100%",
  },
  overviewBox: {
    width: "100%",
    padding: 40,
    borderRadius: 6,
  },
  boxBackground: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    borderRadius: 6,
  },
  goalContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 2,
  },
  goalCircle: {
    height: 18,
    width: 18,
    borderRadius: 100,
    marginRight: 10,
  },
  stepSquare: {
    height: 8,
    width: 8,
    marginRight: 12,
  },
  stepContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  subStepContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subContainer: {
    borderLeftWidth: 1.5,
    marginLeft: 8,
  },
});

export default GoalBot;
