import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { useMediaQuery } from "react-responsive";
import { Text } from "@geist-ui/react";
import RemoteImage from "../../../components/RemoteImage";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Entypo } from "@expo/vector-icons";
import { motion } from "framer-motion";

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

const Navigation = () => {
  const {
    selectTab,
    state: { tabs, selectedTab },
  } = useContext(PlanContext);

  return (
    <View style={styles.container}>
      {tabs.map((elem, index) => {
        let tabContainerStyle;
        let iconName;
        let textColor;
        let textComp;

        if (elem.type == 2) {
          tabContainerStyle = styles.stepContainer;
          iconName = "popup";
          textColor = selectedTab.index == index ? "#00ECAC" : "white";
          textComp = (
            <Text style={{ color: textColor, lineHeight: 1 }} font="14px">
              {elem.title}
            </Text>
          );
        } else {
          const goalSelected =
            selectedTab.index == index ||
            tabs[selectedTab.index].goalOrder == index;
          tabContainerStyle = goalSelected
            ? styles.tabContainerSelected
            : styles.tabContainer;
          iconName =
            elem.type == 0
              ? "home"
              : elem.type == 1 && goalSelected
              ? "chevron-with-circle-down"
              : elem.type == 1 && !goalSelected
              ? "folder"
              : elem.type == 3
              ? "address"
              : elem.type == 4
              ? "bookmark"
              : null;
          textColor = goalSelected ? "#002626" : "white";
          textComp = (
            <Text
              style={{
                color: textColor,
                lineHeight: 1,
                paddingVertical: 0,
                marginVertical: 0,
              }}
              font="15px"
            >
              {elem.title}
            </Text>
          );
        }

        if (elem.type == 2) {
          const selectedTabObject = tabs[selectedTab.index];
          const goalSelected =
            selectedTabObject.type == 1 &&
            selectedTabObject.order == elem.goalOrder
              ? true
              : selectedTabObject.type == 2 &&
                selectedTabObject.goalOrder == elem.goalOrder
              ? true
              : false;
          if (!goalSelected) return null;
        }

        return (
          <motion.div layout transition={spring} key={`${elem.order}`}>
            <TouchableOpacity
              style={tabContainerStyle}
              onPress={() => {
                if (selectedTab.index != index)
                  selectTab({ index, type: "manual" });
              }}
            >
              <Entypo
                name={iconName}
                size={elem.type == 2 ? 14 : 18}
                color={textColor}
                style={{ marginRight: 10 }}
              />
              {textComp}
            </TouchableOpacity>
          </motion.div>
        );
      })}
      <motion.div layout transition={spring}>
        <TouchableOpacity
          style={styles.tabContainer}
          onPress={() => {
            window.open("https://methodfi.com/contact-us", "_blank");
          }}
        >
          <Entypo
            name={"old-phone"}
            size={18}
            color={"white"}
            style={{ marginRight: 10 }}
          />
          <Text
            style={{
              color: "white",
              lineHeight: 1,
              paddingVertical: 0,
              marginVertical: 0,
            }}
            font="15px"
          >
            Schedule Next Call
          </Text>
        </TouchableOpacity>
      </motion.div>
      <motion.div layout transition={spring}>
        <TouchableOpacity
          style={styles.tabContainer}
          onPress={() => {
            window.open("https://docs.methodfi.com/guides/", "_blank");
          }}
        >
          <Entypo
            name={"text-document-inverted"}
            size={18}
            color={"white"}
            style={{ marginRight: 10 }}
          />
          <Text
            style={{
              color: "white",
              lineHeight: 1,
              paddingVertical: 0,
              marginVertical: 0,
            }}
            font="15px"
          >
            API Documentation
          </Text>
        </TouchableOpacity>
      </motion.div>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  tabContainer: {
    paddingLeft: 15,
    paddingRight: 8,
    alignItems: "center",
    width: "100%",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    flexDirection: "row",
    marginBottom: 20,
  },
  tabContainerSelected: {
    backgroundColor: "#00ECAC",

    paddingLeft: 15,
    paddingRight: 8,
    alignItems: "center",
    width: "100%",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    flexDirection: "row",
    marginBottom: 20,
  },
  stepContainer: {
    paddingVertical: 2,
    paddingLeft: 50,
    paddingRight: 8,
    alignItems: "center",
    width: "100%",
    flexDirection: "row",
  },
});

export default Navigation;
