import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";

const TextView = () => {
  return (
    <View style={styles.container}>
      <View
        style={{
          width: "100%",
          alignSelf: "center",
          paddingHorizontal: 50,
          paddingVertical: 20,
          marginTop: 150,
          borderRadius: 10,
          background:
            "linear-gradient(" +
            120 +
            "deg, " +
            "#00946c" +
            ", " +
            "#002626" +
            ")",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <View style={{ width: "60%", paddingVertical: 30 }}>
          <Text
            style={{
              color: "white",
              paddingBottom: 0,
              marginBottom: 0,
              fontFamily: "Roobert",
            }}
            h1
          >
            All Done!
          </Text>
          <Text style={{ color: "white", opacity: 0.65 }} p>
            The payment is now on it’s way to the lender with real-time
            confirmation of the post date. Once the next payment needs to be
            sent to the lender all you need to do is go back to Step 4 and
            create a payment.
          </Text>
        </View>
        <View>
          <Image
            source={require("../../../../../assets/images/gift-dynamic-clay.png")}
            style={{
              height: 200,
              width: 200,
              alignSelf: "center",
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
});

export default TextView;
