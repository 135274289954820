import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import StickyBox from "react-sticky-box";
import Endpoint from "./StepComps/EndpointComponent/Endpoint";
import Local from "./StepComps/LocalComponent/Local";
import _ from "lodash";

const Step = ({ isVisible, order, scrollRef, scrollDown, isScrolling }) => {
  const {
    state: { tabs },
  } = useContext(PlanContext);
  const {
    state: { localDatabase },
  } = useContext(LocalContext);
  const [preloadKeys, setPreloadKeys] = useState(null);
  const windowDims = useWindowDimensions();
  const stepContent = tabs[order];

  useEffect(() => {
    const checkPreload = () => {
      const reqFields =
        tabs[order].content != null && tabs[order].content.requestFields != null
          ? tabs[order].content.requestFields
          : null;
      if (reqFields != null) {
        const filteredFields = reqFields.filter(
          (elem) =>
            elem.hasOwnProperty("preloadKey") & (elem.preloadKey != null)
        );
        if (filteredFields.length > 0) {
          setPreloadKeys(filteredFields);
        }
      }
    };
    checkPreload();
  }, []);

  const tableValues = () =>
    preloadKeys.map((elem) => {
      return {
        key: elem.key,
        description:
          _.get(localDatabase, elem.preloadKey) != null &&
          _.get(localDatabase, elem.preloadDesc) != null
            ? _.get(localDatabase, elem.preloadDesc)
            : elem.desc,
        value:
          _.get(localDatabase, elem.preloadKey) != null
            ? _.get(localDatabase, elem.preloadKey)
            : "Not Selected",
      };
    });

  const descriptionText = stepContent.description
    .split("\n")
    .map((elem, index) => {
      return (
        <Text key={`elem-${index}`} style={{ color: "#002626" }} font="16px">
          {elem.split(/{|}/).map((subElem, subIndex) => {
            if (subElem[0] == "b") {
              return (
                <Text key={`sub-${subIndex}`} span b>
                  {subElem.substring(3)}
                </Text>
              );
            }
            return `${subElem}`;
          })}
        </Text>
      );
    });

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <StickyBox offsetTop={50} offsetBottom={20}>
          <Text
            style={{ color: "#127575", lineHeight: 1.1, fontFamily: "Roobert" }}
            h1
          >
            Step {stepContent.stepNumber}
          </Text>
          <Text
            style={{ color: "#002626", lineHeight: 1.1, fontFamily: "Roobert" }}
            h1
          >
            {stepContent.title}
          </Text>
          <View style={{ marginVertical: 20 }} />

          {descriptionText}

          {preloadKeys != null && preloadKeys.length > 0 ? (
            <View>
              <View style={{ marginVertical: 15 }} />

              <Text style={{ fontFamily: "Roobert" }} h5>
                Dynamic Choices
              </Text>
              <Text style={{ color: "grey" }} small>
                Carried values from previous steps
              </Text>
              <View style={{ marginVertical: 10 }} />
              <Table data={tableValues()}>
                <Table.Column prop="key" label="Key" />
                <Table.Column prop="description" label="Label" />
                <Table.Column prop="value" label="Value" />
              </Table>
            </View>
          ) : null}
        </StickyBox>
      </View>

      <View style={styles.contentContainer}>
        {stepContent.content.type == 1 ? (
          <Endpoint
            order={order}
            stepContent={stepContent}
            scrollRef={scrollRef}
            scrollDown={scrollDown}
            isScrolling={isScrolling}
          />
        ) : null}
        {stepContent.content.type == 2 ? (
          <Local order={order} stepContent={stepContent} />
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textContainer: {
    width: "30%",
  },
  contentContainer: {
    width: "65%",
    paddingBottom: 150,
  },
});

export default Step;
