import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, ScrollView, ActivityIndicator } from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as CodeContext } from "@context/CodeContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import Editor, { useMonaco, loader } from "@monaco-editor/react";
import { defineTheme } from "./EditorThemes";
import { RefreshCw } from "@geist-ui/icons";
import { TabView, TabBar, SceneMap } from "react-native-tab-view";
import { Ionicons } from "@expo/vector-icons";
import Select from "react-select";
import dedent from "dedent-js";
import { motion } from "framer-motion";
import _ from "lodash";

import colors from "@res/colors";
import fonts from "@res/fonts";
import { toast } from "react-toastify";

const languages = [
  { name: "cURL", prism: "bash" },
  { name: "JavaScript (fetch)", prism: "javascript" },
  { name: "Python requests", prism: "python" },
  { name: "Ansible URI", prism: "yaml" },

  { name: "Dart", prism: "dart" },
  { name: "Elixir", prism: "elixir" },
  { name: "Go", prism: "go" },
  // { name: "Java", prism: "java" },
  //{ name: "JSON", prism: "json" },
  // { name: "MATLAB", prism: "matlab" },
  { name: "Node (fetch)", prism: "javascript" },
  { name: "Node (request)", prism: "javascript" },
  { name: "PHP requests", prism: "php" },

  { name: "R httr", prism: "r" },
  // { name: "Rust", prism: "rust" },
  // { name: "Strest", prism: "yaml" },
];

const selectOptions = languages.map((elem) => {
  return { value: elem.name, label: elem.name };
});

const calcEditorHeight = (code) => {
  return code.split(/\r\n|\r|\n/).length * 21 + 10;
};

const CodeEditor = ({
  order,
  onExecute,
  isExecuting,
  curlRequest,
  requestFields,
  changedVars,
}) => {
  const {
    convertRequest,
    setConvertedRequest,
    executeRequest,
    state: { convertedRequest },
  } = useContext(CodeContext);
  const {
    state: { tabs },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const editorRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState({
    value: "cURL",
    label: "cURL",
  });
  const [isConverting, setIsConverting] = useState(false);
  const [language, setLanguage] = useState("shell");
  const [editorCurl, setEditorCurl] = useState(dedent(curlRequest));
  const [vars, setVars] = useState([...requestFields]);
  const [editorHeight, setEditorHeight] = useState(
    calcEditorHeight(curlRequest)
  );

  const editorContent = tabs[order];

  /******
   *
   * CODE FUNCTIONS
   *
   */

  useEffect(() => {
    if (
      convertedRequest != null &&
      convertedRequest != "" &&
      convertedRequest.order == order
    ) {
      console.log("editing client code");
      editClientCode(convertedRequest.code);
      editorRef.current.getAction("editor.action.formatDocument");
      setIsConverting(false);
    }
  }, [convertedRequest]);

  useEffect(() => {
    const changePreloads = async () => {
      let requestCopy = editorCurl;
      changedVars.forEach((elem) => {
        const varIndex = vars.findIndex(
          (subElem) => `${subElem.key}` == `${elem.key}`
        );
        requestCopy = requestCopy.replace(vars[varIndex].value, elem.value);

        const varsClone = _.cloneDeep(vars);
        varsClone[varIndex].value = elem.value;
        setVars(varsClone);
      });

      setEditorCurl(requestCopy);

      if (language == "shell") {
        editClientCode(requestCopy);
        setConvertedRequest(requestCopy);
        setIsConverting(false);
        editorRef.current.getAction("editor.action.formatDocument");
      } else {
        const languageElem = languages.find(
          (elem) => `${elem.prism}` == `${language}`
        );
        setLanguage(languageElem.prism);
        convertRequest({
          requestCode: requestCopy,
          language: languageElem.name,
          order: order,
        });
      }
    };
    if (editorRef.current != null && changedVars.length > 0) changePreloads();
  }, [changedVars]);

  const onExecuteWrapped = () => {
    const currCode = editorRef.current.getValue();
    console.log(currCode);

    const data = parseRequestData(currCode);

    if (data == null) {
      toast.error("Data is not formatted correctly");
    } else {
      // Replace var keys with JSON data
      let flattenedData = {};

      Object.keys(data).forEach((key) => {
        if (typeof data[key] === "object") {
          Object.keys(data[key]).forEach((innerKey) => {
            flattenedData[`${innerKey}`] = data[key][innerKey];
          });
        } else {
          flattenedData[key] = data[key];
        }
      });

      console.log(flattenedData);

      let requestCopy = editorCurl;
      for (let i = 0; i < vars.length; i++) {
        requestCopy = requestCopy.replace(
          vars[i].value,
          _.get(flattenedData, `${vars[i].key}`)
        );
      }

      let clonedReq = requestCopy;
      vars.forEach((v) => {
        const parsed = parseInt(v.value);
        console.log(parsed);
        if (isNaN(parsed) == false && v.type == 2) {
          console.log("Num present");
          clonedReq = clonedReq.replace(`"${v.value}"`, parsed);
        }
      });
      console.log(clonedReq);
      onExecute(clonedReq);

      // requestFields.forEach((elem, i) => {
      //   console.log(`Saving ${elem.key} to localDatabase`);
      //   modDatabase({
      //     key: elem.key,
      //     value: data[`${elem.key}`],
      //   });
      // });
    }
  };

  const onSelectLanguage = (selectedOption) => {
    setSelectedOption(selectedOption);
    const lang = selectedOption.value;
    setIsConverting(true);
    if (lang == "cURL") {
      setLanguage("shell");
      editClientCode(editorCurl);
      setConvertedRequest(editorCurl);
      setIsConverting(false);
      editorRef.current.getAction("editor.action.formatDocument");
    } else {
      const languageElem = languages.find(
        (elem) => `${elem.name}` == `${lang}`
      );
      console.log(languageElem.prism);
      setLanguage(languageElem.prism);
      convertRequest({
        requestCode: editorCurl,
        language: languageElem.name,
        order: order,
      });
    }
  };

  // useEffect(() => {
  //   if (editorRef != null && editorRef.current != null)
  //     monaco.editor.setModelLanguage(editorRef.current.getModel(), language);
  // }, [language]);

  /******
   *
   * EDITOR FUNCTIONS
   *
   */

  useEffect(() => {
    console.log("defining theme");
    defineTheme("blackboard");
  }, []);

  function handleEditorWillMount(monaco) {
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.Latest,
      module: monaco.languages.typescript.ModuleKind.ES2015,
      allowNonTsExtensions: true,
      lib: ["es2018"],
    });
  }

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    monaco.editor.setTheme("blackboard");
    editClientCode(curlRequest);
    setTimeout(() => {
      editClientCode(editorCurl);
    }, 1000);
    //storeEditorRef(editorRef);
  }

  const onEditorChange = () => {};

  const editClientCode = (newValue) => {
    editorRef.current.getModel().setValue(newValue);
    editorRef.current.getAction("editor.action.formatDocument");
    setEditorHeight(calcEditorHeight(newValue));
  };

  const onReset = () => {
    if (language == "shell") {
      editClientCode(editorCurl);
    } else {
      const languageElem = languages.find(
        (elem) => `${elem.name}` == `${selectedOption.value}`
      );
      setLanguage(languageElem.prism);
      convertRequest({
        requestCode: editorCurl,
        language: languageElem.name,
        order: order,
      });
    }
  };

  const parseRequestData = (currCode) => {
    let data = null;
    // Get JSON Data object from code

    const firstVarIndex = currCode.indexOf(vars[0].key);

    const entryBrace = currCode.substring(0, firstVarIndex).lastIndexOf("{");

    const stringPostEntry = currCode.substring(entryBrace + 1);

    const endLastVar =
      currCode.indexOf(vars[vars.length - 1].key) +
      vars[vars.length - 1].key.length;
    const firstExitAfterLast =
      currCode.substring(0, endLastVar).length +
      currCode.substring(endLastVar).indexOf("}");

    const numEntryBraces =
      currCode.substring(entryBrace + 1, firstExitAfterLast).split("{").length -
      1;

    const exitIndices = [];
    for (let i = 0; i < stringPostEntry.length; i++) {
      if (stringPostEntry[i] === "}") exitIndices.push(i);
    }

    const exitBrace =
      currCode.substring(0, entryBrace + 1).length +
      exitIndices[numEntryBraces];

    const dataString = currCode.substring(entryBrace, exitBrace + 1);

    const stringify = eval(`JSON.stringify(${dataString})`);
    console.log(stringify);
    //data = JSON.parse(dataString);
    console.log(JSON.parse(stringify));
    data = JSON.parse(stringify);

    return data;
  };

  // const highlightString = (string) => {

  //   editorRef.current.deltaDecorations([], [
  //     {
  //       range: new monaco.Range()
  //     }
  //   ])
  // }

  return (
    <View style={{ alignSelf: "center", width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Text
          style={{
            paddingTop: 0,
            marginTop: 0,
            paddingBottom: 0,
            marginBottom: 0,
          }}
          h5
        >
          Entity Request
        </Text>
        <View style={{ width: 175, alignSelf: "flex-end" }}>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid black",
                },
                "&:focus": {
                  borderColor: "black",
                },
                "&:active": {
                  borderColor: "black",
                },
                fontSize: 12,
              }),
              menu: (base) => ({
                ...base,
                fontSize: 12,
              }),
            }}
            placeholder="Language"
            onChange={onSelectLanguage}
            value={selectedOption}
            options={selectOptions}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </View>
      </View>
      <motion.div
        key={`motion-div-${order}`}
        style={{ width: "100%", height: "100%" }}
        layout
      >
        <View
          style={[
            codeStyles.editorContainer,
            { height: editorHeight, maxHeight: 500 },
          ]}
        >
          <Editor
            key={`editor-${order}`}
            onChange={onEditorChange}
            path={editorContent.title}
            defaultValue={editorContent.content.codeSnippet}
            defaultLanguage={language}
            height="100%"
            width="100%"
            language={language}
            loading={
              <View>
                <ActivityIndicator color="white" />
              </View>
            }
            beforeMount={handleEditorWillMount}
            onMount={handleEditorDidMount}
            options={{
              formatOnType: true,
              formatOnPaste: true,
              autoIndent: true,
              minimap: { enabled: false },
              scrollbar: {
                horizontal: "hidden",
                vertical: "hidden",
              },
            }}
          />

          <View style={{ position: "absolute", left: 20, bottom: 20 }}>
            <Button
              onClick={() => {
                onReset();
              }}
              auto
              scale={2 / 3}
              px={0.6}
              iconRight={<RefreshCw />}
            />
          </View>

          <View style={{ position: "absolute", bottom: 20, right: 20 }}>
            <Button
              style={{
                padding: 0,
                alignSelf: "flex-start",
                backgroundColor: "#00ECAC",
                borderColor: "#00ECAC",
              }}
              auto
              type="secondary-light"
              onClick={() => {
                onExecuteWrapped();
              }}
              loading={isExecuting}
            >
              <View
                style={{
                  width: 175,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  height: "100%",
                  alignItems: "center",
                  paddingHorizontal: 15,
                }}
              >
                <Text style={{ color: "#002626" }} b>
                  Execute Request
                </Text>
                <Ionicons
                  name="arrow-forward-sharp"
                  size={18}
                  color="#002626"
                />
              </View>
            </Button>
          </View>
        </View>
      </motion.div>
    </View>
  );
};

const codeStyles = StyleSheet.create({
  editorContainer: {
    width: "100%",
    backgroundColor: "#0c1021",
    paddingVertical: 20,
    borderRadius: 8,
  },
});

export default CodeEditor;
