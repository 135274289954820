import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Context as LocalContext } from "@context/LocalContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Dot } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";

const loadedStyles = JSON.stringify({
  container: {
    alignSelf: "center",
    width: 300,
    height: 450,
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,

    elevation: 9,
  },
});

const Success = ({ component }) => {
  const {
    state: { localDatabase },
  } = useContext(LocalContext);

  const profileArr = [
    {
      key: "first_name",
      value: localDatabase.entities.individual.first_name,
      icon: "finger-print",
    },
    {
      key: "last_name",
      value: localDatabase.entities.individual.last_name,
      icon: "finger-print",
    },
    {
      key: "phone",
      value: localDatabase.entities.individual.phone,
      icon: "call",
    },
    {
      key: "email",
      value: localDatabase.entities.individual.email,
      icon: "mail",
    },
    {
      key: "dob",
      value: localDatabase.entities.individual.dob,
      icon: "earth",
    },
  ];

  return (
    <View>
      <JsxParser
        bindings={{
          styles,
          localDatabase,
          profileArr,
        }}
        components={{
          View,
          ScrollView,
          motion,
          ActivityIndicator,
          Text,
          Button,
          Tag,
          TouchableOpacity,
          Card,
          Input,
          Ionicons,
          Image,
        }}
        jsx={component}
      />
    </View>
  );
};

//JSON.parse(loadedStyles)

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default Success;
