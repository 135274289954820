import React from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  Image,
} from "react-native";
import { Loading, Text as GText, Card } from "@geist-ui/react";
import { useMediaQuery } from "react-responsive";

const ShrinkView = () => {
  const windowDims = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * windowDims.width : 500;
  const modalHorizontalPadding = isMobile ? 15 : 50;

  return (
    <ScrollView
      contentContainerStyle={[styles.container, { width: windowDims.width }]}
    >
      <View
        style={[
          styles.modalContainer,
          { width: modalWidth, paddingHorizontal: modalHorizontalPadding },
        ]}
      >
        <View style={{ marginTop: 0, marginBottom: 0 }}>
          <Image
            source={require("../../assets/images/computer-dynamic-premium.png")}
            style={{
              height: 250,
              width: 250,
              alignSelf: "center",
            }}
          />
        </View>

        <View style={{ marginVertical: 15 }} />
        <Card>
          <GText style={{ alignSelf: "center" }}>
            Your screen is too small for the demo tool. Please access this page
            from a desktop computer and expand the window.
          </GText>
        </Card>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#eff3f6",
    paddingHorizontal: "5%",
    paddingTop: 40,
  },
  modalContainer: {
    backgroundColor: "white",
    alignContent: "center",
    paddingVertical: 50,
    alignSelf: "center",
    borderTopColor: "black",
    borderTopWidth: 5,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  spacer: {
    marginVertical: 25,
  },
});

export default ShrinkView;
