import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Image } from "react-native";

const RemoteImage = ({ uri, desiredWidth }) => {
  const [desiredHeight, setDesiredHeight] = React.useState(0);

  Image.getSize(uri, (width, height) => {
    setDesiredHeight((desiredWidth / width) * height - 2);
  });

  return (
    <Image
      source={{ uri: uri }}
      resizeMode="contain"
      style={{
        width: desiredWidth,
        height: desiredHeight,
      }}
    />
  );
};

export default RemoteImage;
