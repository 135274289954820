import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, ScrollView, ActivityIndicator } from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as CodeContext } from "@context/CodeContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import { TabView, TabBar, SceneMap } from "react-native-tab-view";
import { Ionicons } from "@expo/vector-icons";
import CodeEditor from "./CodeEditor";
import CodeFields from "./CodeFields";
import _ from "lodash";
import { Toast } from "@geist-ui/react";

import colors from "@res/colors";
import fonts from "@res/fonts";
import { toast } from "react-toastify";

const renderScene = ({
  route,
  order,
  onExecute,
  isExecuting,
  codeContent,
  changedVars,
  executeTrigger,
}) => {
  switch (route.key) {
    case "first":
      return (
        <CodeFields
          order={order}
          onExecute={onExecute}
          isExecuting={isExecuting}
          curlRequest={codeContent.codeSnippet}
          requestFields={codeContent.requestFields}
          changedVars={changedVars}
          executeTrigger={executeTrigger}
        />
      );
    case "second":
      return (
        <CodeEditor
          order={order}
          onExecute={onExecute}
          isExecuting={isExecuting}
          curlRequest={codeContent.codeSnippet}
          requestFields={codeContent.requestFields}
          changedVars={changedVars}
        />
      );
    default:
      return null;
  }
};

const RequestTabs = ({
  order,
  botRef,
  setDidExecute,
  executeTrigger,
  checkNullPreloads,
}) => {
  const {
    state: { tabs },
  } = useContext(PlanContext);
  const {
    convertRequest,
    setConvertedRequest,
    executeRequest,
    state: { convertedRequest },
  } = useContext(CodeContext);
  const {
    state: { localDatabase },
  } = useContext(LocalContext);
  const [index, setIndex] = useState(1);
  const [routes] = useState([
    { key: "first", title: "Visual" },
    { key: "second", title: "Code" },
  ]);
  const [isExecuting, setIsExecuting] = useState(false);
  const [changedVars, setChangedVars] = useState([]);

  const [nullPreloads, setNullPreloads] = useState(checkNullPreloads());

  useEffect(() => {
    const checkPreloads = () => {
      const varsCopy = _.cloneDeep(tabs[order].content.requestFields);
      const changedArr = [];
      varsCopy.forEach((elem, index) => {
        if (
          elem.preloadKey != null &&
          _.get(localDatabase, elem.preloadKey) != null
        ) {
          if (`${elem.value}` != `${_.get(localDatabase, elem.preloadKey)}`) {
            //varsCopy[index].value = `${_.get(localDatabase, elem.preloadKey)}`;
            changedArr.push({
              key: varsCopy[index].key,
              value: `${_.get(localDatabase, elem.preloadKey)}`,
            });
          }
        }
      });
      setChangedVars(changedArr);
      setNullPreloads(checkNullPreloads());
    };
    checkPreloads();
  }, [localDatabase]);

  const codeContent = tabs[order].content;

  const onExecute = async (request) => {
    console.log(request);
    setDidExecute(false);
    setIsExecuting(true);

    // Check if execution depends on previous
    const preloadArr = checkNullPreloads();

    if (preloadArr == null) {
      try {
        await executeRequest({
          requestCode: request,
          localTitle: tabs[order].dbTitle,
        });
      } catch (e) {
        console.log(e);
        toast.error(
          "An error occurred with that request... Try again or contact us if it happens again"
        );
        return;
      }

      botRef.current.scrollIntoView({
        behavior: "smooth",
      });
      setIsExecuting(false);
      setDidExecute(true);
    } else {
      setIsExecuting(false);
      toast.error(
        "Prior steps must be completed in order to define the variables needed for this request"
      );
    }
  };

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      inactiveColor="grey"
      tabStyle={
        {
          //width: 200,
        }
      }
      indicatorStyle={{ backgroundColor: "grey" }}
      style={{
        backgroundColor: "white",
        marginBottom: 20,
        width: "100%",
        borderBottomWidth: 0,
      }}
      renderLabel={({ route, focused }) => (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {route.title == "Code" ? (
            <Ionicons
              name="ios-code-outline"
              size={16}
              color={"black"}
              style={{ marginRight: 10 }}
            />
          ) : (
            <Ionicons
              name="ios-image"
              size={16}
              color={"black"}
              style={{ marginRight: 10 }}
            />
          )}
          <Text
            style={{
              margin: 8,
              fontSize: 13,
              letterSpacing: 2,
            }}
          >
            {route.title.toUpperCase()}
          </Text>
        </View>
      )}
    />
  );

  return (
    <View style={{ alignSelf: "center", width: "100%", maxWidth: 600 }}>
      <TabView
        renderTabBar={renderTabBar}
        navigationState={{ index, routes }}
        renderScene={({ route }) =>
          renderScene({
            route,
            order,
            onExecute,
            isExecuting,
            codeContent,
            changedVars,
            executeTrigger,
          })
        }
        onIndexChange={setIndex}
      />
      {nullPreloads != null ? (
        <Card style={{ marginTop: 25 }} type="warning">
          <Text small>
            <Text b>Missing variables from previous steps:</Text>{" "}
            {nullPreloads
              .map((elem) => `${elem.desc} (${elem.key})`)
              .join(", ")}
          </Text>
        </Card>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({});

export default RequestTabs;
